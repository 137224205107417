import _interceptor from "../interceptor";
var exports = {};
var interceptor;
interceptor = _interceptor;
/**
 * Rejects the response promise based on the status code.
 *
 * Codes greater than or equal to the provided value are rejected.  Default
 * value 400.
 *
 * @param {Client} [client] client to wrap
 * @param {number} [config.code=400] code to indicate a rejection
 *
 * @returns {Client}
 */

exports = interceptor({
  init: function (config) {
    config.code = config.code || 400;
    return config;
  },
  response: function (response, config) {
    if (response.status && response.status.code >= config.code) {
      return Promise.reject(response);
    }

    return response;
  }
});
export default exports;